import React from 'react'
import './Gallery.css'

function Gallery() {
    return (
        <div>
            <h1 className='gallery-title'>Gallery</h1>
        </div>
    )
}

export default Gallery
