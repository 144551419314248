import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo.png'

import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Button } from './Button'
import './Navbar.css'
import { IconContext } from 'react-icons/lib'


function Navbar() {

    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

   const showButton = () => {
       if(window.innerWidth <= 700){
           setButton(false)
       } else {
           setButton(true)
       }
   }

   useEffect(() => {
       showButton();
   }, []);

   window.addEventListener('resize', showButton)

   return (
       <>
       <IconContext.Provider value={{color: '#1c2769'}}>
        <div className='navbar'>
            <div className='navbar-container container'>
               <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                   <img src={Logo} className='navbar-icon' alt='Logo'/>
               </Link>

               <div className="menu-icon" onClick={handleClick}>
                   {click ? <AiOutlineClose /> : <AiOutlineMenu />}
               </div>

               <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                   <li className='nav-item'>
                       <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                           Home
                       </Link>
                   </li>
                   <li className='nav-item'>
                       <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                           Contact
                       </Link>
                   </li>
                   <li className='nav-item'>
                       <Link to='/gallery' className='nav-links' onClick={closeMobileMenu}>
                           Gallery
                       </Link>
                   </li>

                   <li className='nav-btn'>
                       {button ? (
                               <a href="tel:8638458110">
                               <Button buttonStyle='btn--outline' buttonSize='btn--small' href="tel:8638458110"> Call Now <br/>
                                       <p style={{color: '#2ea7cd', textDecoration: 'none'}} > (863) 845-8110</p>
                               </Button>
                               </a>
                       ) : (
                               <a href="tel:8638458110">
                               <Button buttonStyle='btn--outline'
                                       buttonSize='btn--wide'
                                       onClick={closeMobileMenu}> 
                                       Call Now <br/>
                                       <p style={{color: '#2ea7cd', textDecoration: 'none'}} > (863) 845-8110</p>
                               </Button>
                               </a>
                       )}
                   </li>

               </ul>
            </div>
        </div>
        </IconContext.Provider>
       </>
   )
}

export default Navbar
