import React from 'react'
import './Contact.css'

function Contact() {
    return (
        <div className="contact-container">
            <h1 className='contact-title'>Contact</h1>
            
            <form className='contact-form'>
                <div className='form-control'>
                    <label>Name:</label>
                    <input 
                        type='text' 
                        placeholder='Name'/>
                </div>
                <div className='form-control'>
                    <label>Phone:</label>
                    <input 
                        type='text' 
                        placeholder='Phone Number'/>
                </div>
                <div className='form-control'>
                    <label>Email:</label>
                    <input 
                        type='text' 
                        placeholder='john@example.com'/>
                </div>
                <div className='form-control'>
                    <label>Description:</label>
                    <input 
                        type='text' 
                        placeholder='I was wondering about...'/>
                </div>

                <input type='submit' value='Submit' 
                className='btn btn--primary'/>
            </form>
        </div>
    )
}

export default Contact
