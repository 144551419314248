import React from 'react'
import './App.css';
import { HashRouter, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Gallery from './pages/Gallery'
import Contact from './pages/Contact'


function App() {
  return (
    <HashRouter>
      <Navbar />
      <Route exact path='/' component={Home}/>
      <Route exact path='/gallery' component={Gallery}/>
      <Route exact path='/contact' component={Contact}/>
    </HashRouter>
  );
}

export default App;
