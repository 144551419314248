import React from 'react'
import './Home.css'
// import Button from '../components/Button'
import Background from '../assets/images/home/home-background.jpeg'
import Collisions from '../assets/images/home/collisions.jpg'
import Detailing from '../assets/images/home/detailing.jpg'
import Mechanic from '../assets/images/home/mechanic.jpg'
import Modifications from '../assets/images/home/modifications.jpg'
import Paint from '../assets/images/home/paint.jpg'
import Restorations from '../assets/images/home/restorations.jpg'

function Home() {
    return (
        <div className='home-container'>
            <div className='banner-top'>
                <img src={Background} className='bg-image' alt='Background'/>
                <div className='color-overlay'/>
                <div className='info-top'>
                    <h1 className='bold' style={{color: '#1c2769'}}>JCP Car Repair Services</h1>
                    <p style={{color: '#2ea7cd'}}>Auto Bodywork and Mechanic <br/> <br/> We specialize in Custom Body Modifications, Part Restorations, Paint, and more . . . </p>
                    <br/>
                </div>
                
            </div> 

            <div className='services-block'>
                <h1>Services</h1>
                <div className='grid'>
                    <div className='block'>
                        <div className='color-overlay-grid'/> 
                        <p className='grid-text'>Collisions</p>
                        <img src={Collisions} className='image' alt='collision'/> 
                    </div>
                    <div className='block'>
                        <div className='color-overlay-grid'/> 
                        <p className='grid-text'>Detailing</p>
                        <img src={Detailing} className='image' alt='detailing'/>   
                    </div>
                    <div className='block'>
                        <div className='color-overlay-grid'/>
                        <p className='grid-text'>Mechanic</p>
                        <img src={Mechanic} className='image' alt='mechanic'/>    
                    </div>
                    <div className='block'>
                        <div className='color-overlay-grid'/>
                        <p className='grid-text'>Modifications</p>
                        <img src={Modifications} className='image' alt='modifications'/>    
                    </div>
                    <div className='block'>
                        <div className='color-overlay-grid'/>
                        <p className='grid-text'>Paint</p>
                        <img src={Paint} className='image' alt='paint'/>    
                    </div>
                    <div className='block'>
                        <div className='color-overlay-grid'/>
                        <p className='grid-text'>Restorations</p>
                        <img src={Restorations} className='image' alt='restorations'/>    
                    </div>
                </div>
            </div>

            <div className='banner'>
                <img src={Background} className='bg-image' alt='Background'/>
                <div className='color-overlay'/>
                <div className='info-centered'>
                    <h1 className='bold' style={{color: '#1c2769'}}>JCPMoon Car & Repair Services</h1>
                    <p style={{color: '#2ea7cd'}}>Auto Bodywork Mechanic in Lake Wales </p>
                    <br/>

                    <h3 className='bold' style={{color: '#1c2769'}}>Address</h3>
                    <p style={{color: '#2ea7cd'}}>2015 Old Bartow Road <br/> Lake Wales, FL. 33853</p>
                    <br/>

                    <h3 className='bold' style={{color: '#1c2769'}}>Business Hours</h3>
                    <ul style={{color: '#2ea7cd'}}>
                        <li>Monday: 8:00am - 5:00pm</li>
                        <li>Tuesday: 8:00am - 5:00pm</li>
                        <li>Wednsday: 8:00am - 5:00pm</li>
                        <li>Thursday: 8:00am - 5:00pm</li>
                        <li>Friday: 8:00am - 5:00pm</li>
                        <li>Saturday: 8:00am - 5:00pm</li>
                        <li>Sunday: Closed</li>
                    </ul>
                </div>
            </div>
            
                  
        </div>
    )
}

export default Home
